import React from 'react';

import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import BackgroundImage from 'gatsby-background-image';
import { Primary as PrimaryButton, Secondary as SecondaryButton } from './Button';

const Banner = ({ title, subtitle, image }) => {
	const data = useStaticQuery(graphql`
		query {
			blueBackgroundImage: file(relativePath: { eq: "blue-background.png" }) {
				childImageSharp {
					fluid(quality: 60, maxWidth: 1920) {
						...GatsbyImageSharpFluid_withWebp
					}
				}
			}
		}
	`);
	return (
		// my-80 md:my-100 lg:my-60
		<div className="container mx-auto px-20 md:px-0 rounded-medium shadow-xl overflow-hidden">
			<BackgroundImage
				Tag="section"
				className="bg-cover bg-center"
				fluid={data.blueBackgroundImage.childImageSharp.fluid}
				backgroundColor={`rgba(0, 70, 121, .6)`}>
				<div className="grid grid-cols-2 gap-20">
					<div className="py-80 pl-120">
						<h3 className="text-white font-medium text-md uppercase">{subtitle}</h3>
						<h2 className="text-white font-bold mt-12 mb-30">{title}</h2>
						<SecondaryButton>Schedule a demo</SecondaryButton>
					</div>
					<div className="overflow-hidden relative">
						<span className="pl-10 pt-80 absolute rounded-small">
							<img
								src={image}
								alt={title}
								title={title}
								className="origin-top-left transform scale-110"
							/>
						</span>
					</div>
				</div>
			</BackgroundImage>
		</div>
	);
};

export default Banner;
