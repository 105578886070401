import React from 'react';
import styled from 'styled-components';

const StyledBackdrop = styled.div`
	position: fixed;

	top: 0;
	left: 0;
	right: 0;
	bottom: 0;

	width: 100vw;
	height: 100vh;

	background: rgba(0, 0, 0, 0.9);
`;

export default class Modal extends React.Component {
	onClose = e => {
		if (!e.target.classList.contains('modal-content')) {
			this.props.onClose && this.props.onClose(e);
		}
	};

	render() {
		if (!this.props.show) {
			return null;
		}
		return (
			<div className="z-50 fixed top-0 left-0 right-0 bottom-0 flex flex-col items-center justify-center">
				<StyledBackdrop onClick={this.onClose} />
				<div className="z-50 w-4/6">
					<div className="flex flex-row justify-end p-30" onClick={this.onClose}>
						<span className="text-white hover:text-pictonblue cursor-pointer">
							<svg
								className="fill-current"
								width="18"
								height="18"
								xmlns="http://www.w3.org/2000/svg">
								<path d="M10.429 9L18 1.429 16.571 0 9 7.571 1.429 0 0 1.429 7.571 9 0 16.571 1.429 18 9 10.429 16.571 18 18 16.571z" />
							</svg>
						</span>
					</div>
					{this.props.children}
				</div>
			</div>
		);
	}
}
