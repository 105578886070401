import React, { Component } from 'react';

import Img from 'gatsby-image';
import BackgroundImage from 'gatsby-background-image';

export default ({ title, text, images, backgroundColorClass, backgroundImage }) => (
	<div className="cursor-pointer rounded-medium overflow-hidden shadow-lg bg-white group z-10">
		<BackgroundImage
			Tag="div"
			className={`bg-cover bg-center h-240 relative rounded-t-medium`}
			fluid={backgroundImage.childImageSharp.fluid}
			loading="eager"
			fadeIn={true}
			durationFadeIn={10}>
			<div
				className={`h-full opacity-0 group-hover:opacity-75 duration-500 transition-opacity ${backgroundColorClass}`}></div>

			{images.edges.reverse().map((image, index) => (
				<div
					key={index.toString()}
					className={`overflow-visible translate-y-20 opacity-0 group-hover:opacity-100 transform transition duration-500 translate-x-1/2 group-hover:translate-x-0 mx-12 absolute bottom-0 inset-x-0 mb-${
						index * 20
					} delay-${index * 100}`}>
					{image && image.node && (
						<Img
							fluid={image.node.childImageSharp.fluid}
							// imgStyle={{ filter: 'drop-shadow(0 4px 8px rgba(0,0,0,0.1))' }}
						/>
					)}
				</div>
			))}
		</BackgroundImage>
		<div className="px-30 py-30">
			<h3 className="mb-10">{title}</h3>
			<p>{text}</p>
		</div>
	</div>
);
