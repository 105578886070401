import React, { useState } from 'react';
import styled from 'styled-components';

const StyledVideoContainer = styled.div`
	position: relative;
	padding-bottom: 56.25%;
	height: 0;
	overflow: hidden;

	& iframe,
	& object,
	& embed {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
`;

export default props => (
	<StyledVideoContainer>
		<iframe
			src={props.src}
			frameborder="0"
			allow="autoplay; fullscreen"
			allowfullscreen></iframe>
	</StyledVideoContainer>
);
